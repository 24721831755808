import {parseAsset2} from './asset.serde'
import {SupportSection} from '../types/support-section.types'
import {SupportSectionFieldsFragment} from '../../../../__generated__/datocms.types'

export function parseSupportSection(section: SupportSectionFieldsFragment): SupportSection {
  return {
    __type: 'SupportSection',
    title: section.title!,
    plot: section.plot!,
    description: section.description!,
    phoneNumber: section.phoneNumber!,
    email: section.email!,
    contactTitle: section.contactTitle!,
    contactDescription: section.contactDescription,
    contactName: section.contactName!,
    contactRole: section.contactRole,
    image: parseAsset2(section.image!),
  }
}
